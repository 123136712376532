import { createI18n } from "vue-i18n";
import de_DE from "@/i18n/de-DE.json";
import en_US from "@/i18n/en-US.json";
import getCookie from "@/lib/get-cookie";

const messages = {
  "en-US": {
    ...en_US,
  },
  "de-DE": {
    ...de_DE,
  },
};

let locale = "de-DE";
const localeCookie = getCookie("user_locale", false);

if (localeCookie && ["en-US", "de-DE"].includes(localeCookie)) {
  locale = localeCookie;
}

export default createI18n({ locale, messages });
