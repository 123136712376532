import { LoadingState } from "@/interfaces/module-states";

const state: LoadingState = {
    USER_REGISTER: false,
    USER_ACTIVATE: false,
    USER_LOGIN: false,
    USER_LOGIN_EMAIL: false,
    USER_LOGIN_AS_USER:false,
    USER_LOGOUT: false,
    USER_LOST_PASSWORD: false,
    USER_RECOVER_PASSWORD: false,
    USER_GET_PROFILE: false,
    USER_UPDATE_PROFILE: false,
    USER_UPDATE_PASSWORD: false,
    SCORE_LIST: false,
    SCORE_LIST_SINGLE_USER:false,
    SCORE_GET: false,
    SCORE_ADD: false,
    SCORE_UPDATE: false,
    SCORE_CALCULATE: false,
    USER_LIST: false,
    USER_GET: false,
    CAPTCHA_VERIFY: false,
    AUTHENTICATION_METHOD_CREATE:false,
    USER_REGISTER_RESEND_CONFIRMATION:false,
    AWARD_SET:false,
    AWARD_LIST:false,
    AWARD_DOWNLOAD_CERTIFICATE:false
}

const loadingModule = {
    namespaced: true,
    state,
    actions: {},
    mutations: {
        USER_REGISTER(state: LoadingState, loading: boolean) {
            state.USER_REGISTER = loading;
        },
        USER_ACTIVATE(state: LoadingState, loading: boolean) {
            state.USER_ACTIVATE = loading;
        },
        USER_LOGIN(state: LoadingState, loading: boolean) {
            state.USER_LOGIN = loading;
        },
        USER_LOGIN_AS_USER(state: LoadingState, loading: boolean) {
            state.USER_LOGIN_AS_USER = loading;
        },
        USER_LOGIN_EMAIL(state: LoadingState, loading: boolean) {
            state.USER_LOGIN_EMAIL = loading;
        },
        USER_LOGOUT(state: LoadingState, loading: boolean) {
            state.USER_LOGOUT = loading;
        },
        USER_LOST_PASSWORD(state: LoadingState, loading: boolean) {
            state.USER_LOST_PASSWORD = loading;
        },
        USER_RECOVER_PASSWORD(state: LoadingState, loading: boolean) {
            state.USER_RECOVER_PASSWORD = loading;
        },
        USER_GET_PROFILE(state: LoadingState, loading: boolean) {
            state.USER_GET_PROFILE = loading;
        },
        USER_UPDATE_PROFILE(state: LoadingState, loading: boolean) {
            state.USER_UPDATE_PROFILE = loading;
        },
        USER_UPDATE_PASSWORD(state: LoadingState, loading: boolean) {
            state.USER_UPDATE_PASSWORD = loading;
        },
        SCORE_LIST(state: LoadingState, loading: boolean) {
            state.SCORE_LIST = loading;
        },
        SCORE_LIST_SINGLE_USER(state: LoadingState, loading: boolean) {
            state.SCORE_LIST_SINGLE_USER = loading;
        },
        SCORE_GET(state: LoadingState, loading: boolean) {
            state.SCORE_GET = loading;
        },
        SCORE_CALCULATE(state: LoadingState, loading: boolean) {
            state.SCORE_CALCULATE = loading;
        },
        SCORE_ADD(state: LoadingState, loading: boolean) {
            state.SCORE_ADD = loading;
        },
        SCORE_UPDATE(state: LoadingState, loading: boolean) {
            state.SCORE_UPDATE = loading;
        },
        USER_LIST(state: LoadingState, loading: boolean) {
            state.USER_LIST = loading;
        },
        USER_GET(state: LoadingState, loading: boolean) {
            state.USER_GET = loading;
        },
        USER_REGISTER_RESEND_CONFIRMATION(state: LoadingState, loading: boolean) {
            state.USER_REGISTER_RESEND_CONFIRMATION = loading;
        },
        AUTHENTICATION_METHOD_CREATE(state:LoadingState,loading:boolean){
            state.AUTHENTICATION_METHOD_CREATE = loading
        },
        CAPTCHA_VERIFY(state: LoadingState, loading: boolean) {
            state.CAPTCHA_VERIFY = loading;
        },
        AWARD_SET(state: LoadingState, loading: boolean) {
            state.AWARD_SET = loading;
        },
        AWARD_LIST(state: LoadingState, loading: boolean) {
            state.AWARD_LIST = loading;
        },
        AWARD_DOWNLOAD_CERTIFICATE(state: LoadingState, loading: boolean) {
            state.AWARD_DOWNLOAD_CERTIFICATE = loading;
        },
    }
}

export default loadingModule;
