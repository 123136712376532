<template>
  <router-view/>
</template>

<style lang="scss">
body.modal-open{
    overflow: hidden;
    padding-right: 17px;
}
</style>
