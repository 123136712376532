import Service from "@/lib/service";
import {
  AuthenticationMethod,
  Profile,
  Score,
  User,
} from "@/interfaces/models";
import {
  AddScoreOptions,
  AuthMethodCreateOptions,
  AwardOptions,
  CalculcateScoreOptions,
  CaptchaVerifyOptions,
  ListServiceObjectOptions,
  UpdateScoreOptions,
  UserLoginAsUserOptions,
  UserLoginEmailOptions,
} from "@/interfaces/service-options";
import {
  UserRegisterResponse,
  UserLoginResponse,
  UserLostPasswordResponse,
  UserRecoverPasswordResponse,
  UserUpdatePasswordResponse,
  ScoreListResponse,
  UserListResponse,
  UserLoginEmailResponse,
  CaptchaVerifyResponse,
  AwardListResponse,
} from "@/interfaces/service-response";
import { v4 } from "uuid";
import moment from "moment";

const DUMMY_USER = {
  accountUniqueId: v4(),
  accountType: "ACCOUNT_CONSUMER_INDIVIDUAL",
  authenticationMethods: [
    {
      username: "dummy@stina-global.com",
    },
  ],
  account: {
    customFields: [],
  },
  person: {
    personUniqueId: v4(),
    salutation: null,
    titlePrefix: null,
    titleSuffix: null,
    gender: "MALE",
    firstName: "STINA",
    middleName: null,
    lastName: "Webadmin",
    birthday: "1970-01-01",
    nickname: "dummy_" + v4(),
    height: null,
    heightUnit: null,
    weight: null,
    weightUnit: null,
    avatar: "/img/avatars/EC22_RO22_ATHLETES_COMIC_AlmaBestvater.png",
    languageIso2: null,
    addresses: [],
    customFields: [],
    contactInformations: [],
  },
  organization: null,
  channel: {
    channelUniqueId: v4(),
  },
  contactPerson: {
    personUniqueId: v4(),
    salutation: null,
    titlePrefix: null,
    titleSuffix: null,
    gender: "MALE",
    firstName: "STINA",
    middleName: null,
    lastName: "Webadmin",
    birthday: "1970-01-01",
    nickname: "dummy_" + v4(),
    height: null,
    heightUnit: null,
    weight: null,
    weightUnit: null,
    avatar: "/img/avatars/EC22_RO22_ATHLETES_COMIC_AlmaBestvater.png",
    languageIso2: null,
    addresses: [],
    customFields: [],
    contactInformations: [],
  },
  subscriptions: [],
  roles: ["USER"],
};

export default new (class SpikeringService extends Service {
  /**
   * Registers a new user within the service.
   *
   * @param {User} user
   * @returns Promise<UserRegisterResponse>
   */
  USER_REGISTER(user: User): Promise<UserRegisterResponse> {
    const payload = {
      channelUniqueId: process.env.VUE_APP_CHANNEL_UNIQUE_ID,
      ...user,
    };

    return this.request("USER_REGISTER", payload, "PUBLIC");
  }
  /**
   * Registers a new user within the service.
   *
   * @param {String} token
   * @returns Promise<Record<string, unknown>>
   */
  USER_ACTIVATE(token: string): Promise<Record<string, unknown>> {
    const payload = {
      channelUniqueId: process.env.VUE_APP_CHANNEL_UNIQUE_ID,
      token,
    };

    return this.request("USER_ACTIVATE", payload, "PUBLIC");
  }
  /**
   * Resend activation link to user email.
   *
   * @param {String} username
   * @returns Promise<Record<string, unknown>>
   */
  USER_REGISTER_RESEND_CONFIRMATION(
    username: string
  ): Promise<Record<string, unknown>> {
    const payload = {
      channelUniqueId: process.env.VUE_APP_CHANNEL_UNIQUE_ID,
      username,
    };

    return this.request("USER_REGISTER_RESEND_CONFIRMATION", payload, "PUBLIC");
  }

  /**
   * @param {AuthenticationMethod} authenticationMethod
   * @returns {Promise<UserLoginResponse>}
   */
  USER_LOGIN(
    authenticationMethod: AuthenticationMethod
  ): Promise<UserLoginResponse> {
    const payload = {
      channelUniqueId: process.env.VUE_APP_CHANNEL_UNIQUE_ID,
      authenticationMethodType: authenticationMethod.authenticationMethodType,
      username: authenticationMethod.username,
      badge: authenticationMethod.badge,
      password: authenticationMethod.password,
    };

    return this.request("USER_LOGIN", payload, "PUBLIC");
  }

  /**
   * @param {UserLoginEmailOptions} options
   * @returns {Promise<UserLoginEmailResponse>}
   */
  USER_LOGIN_EMAIL(
    options: UserLoginEmailOptions
  ): Promise<UserLoginEmailResponse> {
    const payload = {
      channelUniqueId: process.env.VUE_APP_CHANNEL_UNIQUE_ID,
      authenticationMethodType: options.authenticationMethodType,
      username: options.username,
      badge: options.badge,
    };

    return this.request("USER_LOGIN_EMAIL", payload, "PUBLIC");
  }

  /**
   * @param {UserLoginAsUserOptions} options
   * @returns {Promise<UserLoginEmailResponse>}
   */

  USER_LOGIN_AS_USER(
    payload: UserLoginAsUserOptions
  ): Promise<UserLoginEmailResponse> {
    return this.request("USER_LOGIN_AS_USER", payload, "WEBAPP");
  }

  /**
   * @param {String} username
   * @returns {Promise<UserLostPasswordResponse>}
   */
  USER_LOST_PASSWORD(username: string): Promise<UserLostPasswordResponse> {
    const payload = {
      channelUniqueId: process.env.VUE_APP_CHANNEL_UNIQUE_ID,
      username,
      passwordResetUrl: process.env.VUE_APP_DOMAIN + "/password-recovery",
      passwordResetExpiresInSeconds: 86400,
    };

    return this.request("USER_LOST_PASSWORD", payload, "PUBLIC");
  }

  /**
   * @param {String} token
   * @param {String} newPassword
   * @returns {Promise<UserRecoverPasswordResponse>}
   */
  USER_RECOVER_PASSWORD(
    token: string,
    newPassword: string
  ): Promise<UserRecoverPasswordResponse> {
    const payload = {
      channelUniqueId: process.env.VUE_APP_CHANNEL_UNIQUE_ID,
      token,
      newPassword,
    };

    return this.request("USER_RECOVER_PASSWORD", payload, "PUBLIC");
  }

  /**
   * @returns {Promise<Profile>}
   */
  USER_GET_PROFILE(context?: string, payload?: object): Promise<Profile> {
    return this.request("USER_GET_PROFILE", payload, context);
  }

  /**
   * @param {User} user
   * @returns {Promise<Profile>}
   */
  USER_UPDATE_PROFILE(user: User, context: string): Promise<Profile> {
    return this.request("USER_UPDATE_PROFILE", user, context);
  }

  /**
   * @param {String} username
   * @param {String} password
   * @param {String} newPassword
   * @returns {Promise<UserUpdatePasswordResponse>}
   */
  USER_UPDATE_PASSWORD(
    username: string,
    password: string,
    newPassword: string
  ): Promise<UserUpdatePasswordResponse> {
    return this.request("USER_UPDATE_PASSWORD", {
      username,
      password,
      newPassword,
    });
  }

  /**
   * @param {Object} payload
   * @returns {Promise<Object>}
   */
  AUTHENTICATION_METHOD_CREATE(
    payload: AuthMethodCreateOptions
  ): Promise<UserUpdatePasswordResponse> {
    return this.request("AUTHENTICATION_METHOD_CREATE", payload);
  }

  /**
   * @param {ListServiceObjectOptions} options
   * @returns Promise<ScoreListResponse>
   */
  SCORE_LIST(options: ListServiceObjectOptions): Promise<ScoreListResponse> {
    // return new Promise((resolve) => {
    //   resolve({
    //     page: 1,
    //     itemsPerPage: 10,
    //     limit: null,
    //     total: 0,
    //     list: [],
    //   });
    // });
    return this.request("SCORE_LIST", options, "PUBLIC");
  }

  /**
   * @param {String} scoreUniqueId
   * @returns Promise<Score>
   */
  SCORE_GET(scoreUniqueId: string): Promise<Score> {
    return new Promise((resolve) => {
      resolve({
        scoreUniqueId,
        scoreTimestamp: moment().format("YYYY-MM-DD"),
        scoreAchievedPoints: 1,
        scoreAvatar:
          "/img/avatars/EC22_RO22_ATHLETES_COMIC_ChristinaHering.png",
        scoreValue: 3.33,
      });
    });
    //return this.request( 'SCORE_GET', { scoreUniqueId }, 'PUBLIC' );
  }

  /**
   * @param {String} scoreUniqueId
   * @returns Promise<Score>
   */
  SCORE_CALCULATE(payload: CalculcateScoreOptions): Promise<Score> {
    return this.request("SCORE_CALCULATE", payload, "WEBAPP");
  }

  /**
   * @param {Object} payload
   * @returns Promise<Score>
   */
  SCORE_ADD(payload: AddScoreOptions): Promise<Score> {
    return this.request("SCORE_ADD", payload, "WEBAPP");
  }

  /**
   * @param {Object} payload
   * @returns Promise<Score>
   */
  SCORE_UPDATE(payload: UpdateScoreOptions): Promise<Score> {
    return this.request("SCORE_UPDATE", payload, "WEBAPP");
  }

  /**
   * @param {ListServiceObjectOptions} options
   * @returns Promise<UserListResponse>
   */
  USER_LIST(options: ListServiceObjectOptions): Promise<UserListResponse> {
    // const list: User[] = [];
    // for (let i = 0; i < 10; i++) {
    //   list.push(DUMMY_USER);
    // }
    // return new Promise((resolve) => {
    //   resolve({
    //     page: 1,
    //     itemsPerPage: 10,
    //     limit: null,
    //     total: 100,
    //     list,
    //   });
    // });
    return this.request("USER_LIST", options, "WEBAPP");
  }

  /**
   * @param {String} userUniqueId
   * @returns Promise<User>
   */
  USER_GET(userUniqueId: string): Promise<User> {
    return new Promise((resolve) => {
      resolve(DUMMY_USER);
    });
    // return this.request( 'USER_GET', { userUniqueId }, 'WEBAPP' );
  }

  /**
   * @param {CaptchaVerifyOptions} options
   * @returns Promise<CaptchaVerifyResponse>
   */
  CAPTCHA_VERIFY(
    options: CaptchaVerifyOptions
  ): Promise<CaptchaVerifyResponse> {
    const payload = {
      channelUniqueId: process.env.VUE_APP_CHANNEL_UNIQUE_ID,
      ...options,
    };

    return this.request("CAPTCHA_VERIFY", payload, "PUBLIC");
  }
  /**
   * @param {AwardOptions} options
   * @returns Promise<CaptchaVerifyResponse>
   */
  AWARD_SET(options: AwardOptions): Promise<any> {
    const payload = {
      awardUniqueId: options.awardUniqueId,
      personUniqueId: options.personUniqueId,
      organizationUniqueId: options.organizationUniqueId,
    };
    return this.request(options.requestType, payload, "WEBAPP");
  }

  /**
   * @param {ListServiceObjectOptions} options
   * @returns Promise<ScoreListResponse>
   */
  AWARD_LIST(
    options: ListServiceObjectOptions,
    context: "WEBAPP" | "USER" = "WEBAPP"
  ): Promise<AwardListResponse> {
    return this.request("AWARD_PERSON_LIST", options, context);
  }
  AWARD_DOWNLOAD_CERTIFICATE(options: {
    mediaObjectUniqueId: string;
    contentDisposition: string;
    context: "WEBAPP" | "USER";
  }): Promise<AwardListResponse> {
    const payload = {
      mediaObjectUniqueId: options.mediaObjectUniqueId,
      contentDisposition: options.contentDisposition,
    };
    return this.request(
      "MEDIA_OBJECT_DOWNLOAD",
      payload,
      options.context,
      "",
      "blob",
      true
    );
  }
})();
