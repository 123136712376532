import {
  AwardOptions,
  ListServiceObjectOptions,
} from "@/interfaces/service-options";
import spikeringService from "@/services/spikering.service";
import { Commit, Dispatch } from "vuex";

const awardState = {
  awards: {
    page: 1,
    itemsPerPage: 10,
    limit: null,
    total: 0,
    list: [],
  },
};

const awardModule = {
  namespaced: true,
  mutations: {
    populateAwards(
      state: any,
      awards: {
        page: number;
        itemsPerPage: number;
        limit: number | null;
        total: number;
        list: any[];
      }
    ) {
      state.awards = awards;
    },
  },
  actions: {
    async SET({ commit }: { commit: Commit }, payload: AwardOptions) {
      commit("loading/AWARD_SET", true, { root: true });
      try {
        const res = await spikeringService.AWARD_SET(payload);
        return res;
      } catch (error: any) {
        return Promise.reject(error);
      } finally {
        commit("loading/AWARD_SET", false, { root: true });
      }
    },
    async LIST(
      { commit }: { commit: Commit },
      payload: ListServiceObjectOptions & { context?: "WEBAPP" | "USER" }
    ) {
      let context: "WEBAPP" | "USER" = "WEBAPP";
      if (payload.context) {
        context = payload.context;
        delete payload.context;
      }
      
      commit("loading/AWARD_LIST", true, { root: true });
      try {
        const res = await spikeringService.AWARD_LIST(payload, context);
        commit("populateAwards", res);
        return res;
      } catch (error: any) {
        return Promise.reject(error);
      } finally {
        commit("loading/AWARD_LIST", false, { root: true });
      }
    },
    async DOWNLOAD_CERTIFICATE(
      { commit }: { commit: Commit },
      payload: {
        mediaObjectUniqueId: string;
        contentDisposition: string;
        context: "WEBAPP" | "USER";
      }
    ) {
      commit("loading/AWARD_DOWNLOAD_CERTIFICATE", true, { root: true });
      try {
        const res = await spikeringService.AWARD_DOWNLOAD_CERTIFICATE(payload);
        return res;
      } catch (error: any) {
        return Promise.reject(error);
      } finally {
        commit("loading/AWARD_DOWNLOAD_CERTIFICATE", false, { root: true });
      }
    },
    // async DOWNLOAD_USER_CERTIFICATE({
    //   commit,
    //   dispatch,
    // }: {
    //   commit: Commit;
    //   dispatch: Dispatch;
    // }) {
    //   try {
    //     const res = await dispatch("LIST", {
    //       filter: {},
    //       order: {},
    //       page: 1,
    //       itemsPerPage: 5,
    //       limit: null,
    //       context: "USER",
    //     });
    //     const certificateObj = res.list.find((award: any) => {
    //       return award.awardAssignmentItems.length > 0;
    //     });
    //     if (certificateObj) {
    //       const mediaObjectUniqueId =
    //         certificateObj.awardAssignmentItems[0].awardAssignmentItemPayload
    //           .mediaObjectUniqueId;
    //       return dispatch("DOWNLOAD_CERTIFICATE", {
    //         mediaObjectUniqueId,
    //         contentDisposition: "attachment",
    //         context: "USER",
    //       });
    //     }
    //   } catch (error: any) {
    //     return Promise.reject(error);
    //   }
    // },
  },
};

export default awardModule;
