import SpikeringService from "@/services/spikering.service";
import getCookie from "@/lib/get-cookie";
import deleteCookie from "@/lib/delete-cookie";
import setCookie from "@/lib/set-cookie";
import { Commit } from "vuex";
import {
  AuthMethodCreateOptions,
  ListServiceObjectOptions,
  UserLoginAsUserOptions,
  UserLoginEmailOptions,
  UserRecoverPasswordOptions,
  UserUpdatePasswordOptions,
} from "@/interfaces/service-options";
import { UserState } from "@/interfaces/module-states";
import { AuthenticationMethod, Profile, User } from "@/interfaces/models";
import {
  UserListResponse,
  UserLoginEmailResponse,
  UserLoginResponse,
} from "@/interfaces/service-response";

const token = getCookie("user_token", false);
// const person = getCookie("user_person");
const personJSON = localStorage.getItem("user_person");
const person = personJSON ? JSON.parse(personJSON) : undefined;

// const roles = getCookie("user_roles");
const rolesJSON = localStorage.getItem("user_roles");
const roles = rolesJSON ? JSON.parse(rolesJSON) : undefined;

// const organization = getCookie("user_organization");
const organizationJSON = localStorage.getItem("user_organization");
const organization = organizationJSON
  ? JSON.parse(organizationJSON)
  : undefined;

// const authenticationMethod = getCookie("user_authentication_method");
const authenticationMethodJSON = localStorage.getItem(
  "user_authentication_method"
);
const authenticationMethod = authenticationMethodJSON
  ? JSON.parse(authenticationMethodJSON)
  : undefined;
// const user = getCookie("loggedin_as_user");
const userJSON = localStorage.getItem("loggedin_as_user");
const user = userJSON ? JSON.parse(userJSON) : undefined;

const userUniqueId = getCookie("user_unique_id", false);
const state: UserState = {
  token: token ? token : undefined,
  person: person ? person : undefined,
  roles: roles ? roles : undefined,
  organization: organization ? organization : undefined,
  authenticationMethod: authenticationMethod ? authenticationMethod : undefined,
  userUniqueId: userUniqueId ? userUniqueId : undefined,
  user: user ? user : undefined,
  users: {
    page: 1,
    itemsPerPage: 10,
    limit: null,
    total: 0,
    list: [],
  },
};

const userModule = {
  namespaced: true,
  state,
  actions: {
    REGISTER({ commit }: { commit: Commit }, user: User) {
      commit("loading/USER_REGISTER", true, { root: true });
      return new Promise((resolve, reject) => {
        SpikeringService.USER_REGISTER(user)
          .then((user) => {
            commit("loading/USER_REGISTER", false, { root: true });
            resolve(user);
          })
          .catch((error) => {
            commit("clearUser");
            commit("loading/USER_REGISTER", false, { root: true });
            reject(error);
          });
      });
    },
    ACTIVATE({ commit }: { commit: Commit }, token: string): Promise<boolean> {
      commit("loading/USER_ACTIVATE", true, { root: true });
      return new Promise((resolve, reject) => {
        SpikeringService.USER_ACTIVATE(token)
          .then(() => {
            commit("loading/USER_ACTIVATE", false, { root: true });
            resolve(true);
          })
          .catch(() => {
            commit("loading/USER_ACTIVATE", false, { root: true });
            reject(false);
          });
      });
    },
    REGISTER_RESEND_CONFIRMATION(
      { commit }: { commit: Commit },
      email: string
    ): Promise<boolean> {
      commit("loading/USER_REGISTER_RESEND_CONFIRMATION", true, { root: true });
      return new Promise((resolve, reject) => {
        SpikeringService.USER_REGISTER_RESEND_CONFIRMATION(email)
          .then(() => {
            commit("loading/USER_REGISTER_RESEND_CONFIRMATION", false, {
              root: true,
            });
            resolve(true);
          })
          .catch(() => {
            commit("loading/USER_REGISTER_RESEND_CONFIRMATION", false, {
              root: true,
            });
            reject(false);
          });
      });
    },
    LOGIN(
      { commit }: { commit: Commit },
      authenticationMethod: AuthenticationMethod
    ) {
      commit("loading/USER_LOGIN", true, { root: true });
      return new Promise((resolve, reject) => {
        SpikeringService.USER_LOGIN(authenticationMethod)
          .then((userLoginResponse: UserLoginResponse) => {
            const options = { expires: 86400 };
            setCookie("user_token", userLoginResponse.token, options);

            SpikeringService.USER_GET_PROFILE()
              .then((userGetProfileResponse) => {
                const person = JSON.stringify(userGetProfileResponse.person);
                const account = JSON.stringify(userGetProfileResponse.account);
                const userUniqueId = userGetProfileResponse.user.userUniqueId;

                const roles = JSON.stringify(userGetProfileResponse.roles);
                const organization = JSON.stringify(
                  userGetProfileResponse.organization
                );
                const authenticationMethod = JSON.stringify(
                  userGetProfileResponse.authenticationMethod
                );

                // setCookie("user_person", person, options);
                localStorage.setItem("user_person", person);
                setCookie("user_unique_id", userUniqueId, options);
                localStorage.setItem("user_roles", roles);
                // setCookie("user_roles", roles, options);
                localStorage.setItem("user_organization", organization);
                // setCookie("user_organization", organization, options);
                localStorage.setItem(
                  "user_authentication_method",
                  authenticationMethod
                );
                // setCookie(
                //   "user_authentication_method",
                //   authenticationMethod,
                //   options
                // );

                const state = {
                  token: userLoginResponse.token,
                  person: userGetProfileResponse.person,
                  account: userGetProfileResponse.account,
                  roles: userGetProfileResponse.roles,
                  organization: userGetProfileResponse.organization
                    ? userGetProfileResponse.organization
                    : undefined,
                  authenticationMethod:
                    userGetProfileResponse.authenticationMethod,
                  userUniqueId,
                };

                commit("populateState", state);
                commit("loading/USER_LOGIN", false, { root: true });
                resolve(state);
                //redirect to homepage
                // window.location.href = "/";
              })
              .catch((error) => {
                commit("clearUser");
                commit("loading/USER_LOGIN", false, { root: true });
                reject(error);
              });
          })
          .catch((error) => {
            commit("clearUser");
            commit("loading/USER_LOGIN", false, { root: true });
            reject(error);
          });
      });
    },
    LOGIN_EMAIL(
      { commit }: { commit: Commit },
      options: UserLoginEmailOptions
    ) {
      commit("loading/USER_LOGIN_EMAIL", true, { root: true });
      return new Promise((resolve, reject) => {
        SpikeringService.USER_LOGIN_EMAIL(options)
          .then((response: UserLoginEmailResponse) => {
            commit("loading/USER_LOGIN_EMAIL", false, { root: true });
            resolve(response);
          })
          .catch((error) => {
            commit("clearUser");
            commit("loading/USER_LOGIN_EMAIL", false, { root: true });
            reject(error);
          });
      });
    },
    async LOGIN_AS_USER(
      { commit }: { commit: Commit },
      payload: UserLoginAsUserOptions
    ) {
      commit("loading/USER_LOGIN_AS_USER", true, { root: true });
      try {
        const response = await SpikeringService.USER_LOGIN_AS_USER(payload);
        return response;
      } catch (error: any) {
        throw new Error(error);
      } finally {
        commit("loading/USER_LOGIN_AS_USER", true, { root: false });
      }
    },
    LOGOUT({ commit }: { commit: Commit }) {
      commit("loading/USER_LOGOUT", true, { root: true });
      return new Promise((resolve) => {
        deleteCookie("user_token");
        // deleteCookie("user_person");
        localStorage.removeItem("user_person");
        localStorage.removeItem("user_roles");
        localStorage.removeItem("user_organization");
        localStorage.removeItem("user_authentication_method");
        // deleteCookie("user_roles");
        // deleteCookie("user_organization");
        // deleteCookie("user_authentication_method");
        deleteCookie("badgeId");
        deleteCookie("user_locale");
        deleteCookie("user_unique_id");
        localStorage.removeItem("loggedin_as_user");
        // deleteCookie("loggedin_as_user");
        commit("clearState");
        commit("loading/USER_LOGOUT", false, { root: true });
        commit("setBadgeId", undefined, { root: true });
        resolve(true);
      });
    },
    LOST_PASSWORD({ commit }: { commit: Commit }, username: string) {
      commit("loading/USER_LOST_PASSWORD", true, { root: true });
      return new Promise((resolve, reject) => {
        SpikeringService.USER_LOST_PASSWORD(username)
          .then(() => {
            commit("loading/USER_LOST_PASSWORD", false, { root: true });
            resolve(true);
          })
          .catch((error) => {
            commit("loading/USER_LOST_PASSWORD", false, { root: true });
            reject(error);
          });
      });
    },
    RECOVER_PASSWORD(
      { commit }: { commit: Commit },
      recoverPasswordOptions: UserRecoverPasswordOptions
    ) {
      commit("loading/USER_RECOVER_PASSWORD", true, { root: true });
      return new Promise((resolve, reject) => {
        SpikeringService.USER_RECOVER_PASSWORD(
          recoverPasswordOptions.token,
          recoverPasswordOptions.newPassword
        )
          .then(() => {
            commit("loading/USER_RECOVER_PASSWORD", false, { root: true });
            resolve(true);
          })
          .catch((error) => {
            commit("loading/USER_RECOVER_PASSWORD", false, { root: true });
            reject(error);
          });
      });
    },
    GET_PROFILE({ commit }: { commit: Commit }, payload: Record<string, any>) {
      const data = payload && payload.data ? payload.data : undefined;
      const context = payload && payload.context ? payload.context : "USER";
      const populateUser =
        payload && payload.populate ? payload.populate : false;
      if (payload && !payload.persistCurrentUser) {
        commit("clearUser");
      }

      commit("loading/USER_GET_PROFILE", true, { root: true });
      return new Promise((resolve, reject) => {
        SpikeringService.USER_GET_PROFILE(context, data)
          .then((profile) => {
            commit("loading/USER_GET_PROFILE", false, { root: true });
            if (populateUser) {
              commit("populateUser", profile);
              const options = { expires: 86400 };
              localStorage.setItem("loggedin_as_user", JSON.stringify(profile));
              // setCookie("loggedin_as_user", JSON.stringify(profile), options);
            }
            resolve(profile);
          })
          .catch((error) => {
            commit("loading/USER_GET_PROFILE", false, { root: true });
            reject(error);
          });
      });
    },
    UPDATE_PROFILE(
      { commit }: { commit: Commit },
      payload: { user: User; context: string; asUser: boolean }
    ): Promise<Profile> {
      commit("loading/USER_UPDATE_PROFILE", true, { root: true });
      return new Promise((resolve, reject) => {
        SpikeringService.USER_UPDATE_PROFILE(payload.user, payload.context)
          .then((response) => {
            if (payload.context === "USER") {
              const options = { expires: 86400 };
              const person = JSON.stringify(response.person);
              const account = JSON.stringify(response.account);
              const roles = JSON.stringify(response.roles);
              const organization = JSON.stringify(response.organization);
              const authenticationMethod = JSON.stringify(
                response.authenticationMethod
              );
              // setCookie("user_person", person, options);
              localStorage.setItem("user_person", person);
              localStorage.setItem("user_roles", roles);
              // setCookie("user_roles", roles, options);
              localStorage.setItem("user_organization", organization);
              // setCookie("user_organization", organization, options);
              localStorage.setItem(
                "user_authentication_method",
                authenticationMethod
              );
              // setCookie(
              //   "user_authentication_method",
              //   authenticationMethod,
              //   options
              // );
              const state = {
                person: response.person,
                account: response.account,
                roles: response.roles,
                organization: response.organization
                  ? response.organization
                  : undefined,
                authenticationMethod: response.authenticationMethod,
              };
              commit("populateState", state);
            }
            if (payload.asUser) {
              commit("populateUser", response);
              const options = { expires: 86400 };
              localStorage.setItem(
                "loggedin_as_user",
                JSON.stringify(response)
              );
              // setCookie("loggedin_as_user", JSON.stringify(response), options);
            }
            commit("loading/USER_UPDATE_PROFILE", false, { root: true });
            resolve(response);
          })
          .catch((error) => {
            commit("loading/USER_UPDATE_PROFILE", false, { root: true });
            //console.log(error.response.data.returnCode);
            reject(error);
          });
      });
    },
    UPDATE_PASSWORD(
      { commit }: { commit: Commit },
      updatePasswordOptions: UserUpdatePasswordOptions
    ) {
      commit("loading/USER_UPDATE_PASSWORD", true, { root: true });
      return new Promise((resolve, reject) => {
        SpikeringService.USER_UPDATE_PASSWORD(
          updatePasswordOptions.username,
          updatePasswordOptions.password,
          updatePasswordOptions.newPassword
        )
          .then((profile) => {
            commit("loading/USER_UPDATE_PASSWORD", false, { root: true });
            resolve(profile);
          })
          .catch((error) => {
            commit("loading/USER_UPDATE_PASSWORD", false, { root: true });
            reject(error);
          });
      });
    },
    LIST(
      { commit }: { commit: Commit },
      options: ListServiceObjectOptions
    ): Promise<UserListResponse> {
      commit("loading/USER_LIST", true, { root: true });
      return new Promise((resolve, reject) => {
        SpikeringService.USER_LIST(options)
          .then((response: UserListResponse) => {
            commit("populateUsers", response);
            commit("loading/USER_LIST", false, { root: true });
            resolve(response);
          })
          .catch((error) => {
            commit("clearScores");
            commit("loading/USER_LIST", false, { root: true });
            reject(error);
          });
      });
    },
    GET({ commit }: { commit: Commit }, userUniqueId: string) {
      commit("loading/USER_GET", true, { root: true });
      return new Promise((resolve, reject) => {
        SpikeringService.USER_GET(userUniqueId)
          .then((response: User) => {
            commit("populateUser", response);
            commit("loading/USER_GET", false, { root: true });
            resolve(response);
          })
          .catch((error) => {
            commit("clearScore");
            commit("loading/USER_GET", false, { root: true });
            reject(error);
          });
      });
    },
    async AUTHENTICATION_METHOD_CREATE(
      { commit }: { commit: Commit },
      payload: AuthMethodCreateOptions
    ) {
      commit("loading/AUTHENTICATION_METHOD_CREATE", true, { root: true });
      try {
        const res = await SpikeringService.AUTHENTICATION_METHOD_CREATE(
          payload
        );
        commit("addBadgeToAuthMethod", payload.badge);

        return res;
      } catch (error: any) {
        return Promise.reject(error);
      } finally {
        commit("loading/AUTHENTICATION_METHOD_CREATE", false, { root: true });
      }
    },
  },
  mutations: {
    clearState(state: UserState) {
      state.user = undefined;
      state.token = undefined;
      state.person = undefined;
      state.roles = undefined;
      state.organization = undefined;
      state.authenticationMethod = {
        username: null,
        badge: null,
      };
    },
    populateState(state: UserState, newState: UserState) {
      if (newState.token) {
        state.token = newState.token;
      }

      state.person = newState.person;
      state.roles = newState.roles;
      state.organization = newState.organization;
      state.authenticationMethod = newState.authenticationMethod;
      state.userUniqueId = newState.userUniqueId;
    },
    populateUsers(
      state: UserState,
      users: {
        page: number;
        itemsPerPage: number;
        limit: number | null;
        total: number;
        list: User[];
      }
    ) {
      state.users = users;
    },
    clearUser(state: UserState) {
      localStorage.removeItem("loggedin_as_user");
      state.user = undefined;
    },
    populateUser(state: UserState, user: User) {
      state.user = user;
    },
    addBadgeToAuthMethod(state: UserState, badge: string) {
      if (!state.authenticationMethod) return;
      state.authenticationMethod.badge = badge;
    },
  },
  getters: {
    fullName: (state: UserState): string | undefined => {
      // if (!state.person || !state.person.firstName || !state.person.lastName) {
      //   return undefined;
      // }
      if (state.person && state.person.firstName && !state.person.lastName) {
        return state.person.firstName;
      } else if (
        state.person &&
        state.person.lastName &&
        !state.person.firstName
      ) {
        return state.person.lastName;
      } else if (
        state.person &&
        state.person.firstName &&
        state.person.lastName
      ) {
        return state.person.firstName + " " + state.person.lastName;
      } else {
        return undefined;
      }
    },
    email: (state: UserState): string | undefined | null => {
      return state.authenticationMethod?.username;
    },
    badge: (state: UserState): string | undefined | null => {
      return state.authenticationMethod?.badge;
    },
    nickname: (state: UserState): string | undefined | null => {
      return state.person?.nickname;
    },
    isLoggedIn: (state: UserState): boolean => {
      return (
        state.token !== undefined &&
        state.person !== undefined &&
        state.roles !== undefined &&
        state.organization !== undefined &&
        state.authenticationMethod !== undefined
      );
    },
    isWebAppUser: (state: UserState): boolean => {
      if (!state.roles) {
        return false;
      }

      return state.roles.includes("WEBAPP");
    },
    authenticationMethod: (state: UserState) => {
      return state.authenticationMethod;
    },
  },
};

export default userModule;
