import { ScoreState } from "@/interfaces/module-states";
import { Commit } from "vuex";
import {
  AddScoreOptions,
  CalculcateScoreOptions,
  ListServiceObjectOptions,
  UpdateScoreOptions,
} from "@/interfaces/service-options";
import SpikeringService from "@/services/spikering.service";
import { Score } from "@/interfaces/models";
import { ScoreListResponse } from "@/interfaces/service-response";

const state: ScoreState = {
  scores: {
    page: 1,
    itemsPerPage: 10,
    limit: null,
    total: 0,
    list: [],
  },
  score: undefined,
};

const scoreModule = {
  namespaced: true,
  state,
  actions: {
    LIST(
      { commit }: { commit: Commit },
      options: ListServiceObjectOptions & { populateScore?: boolean }
    ): Promise<ScoreListResponse> {
      let populate = true;
      if (options.populateScore == false) populate = false;
      commit("loading/SCORE_LIST", true, { root: true });
      return new Promise((resolve, reject) => {
        SpikeringService.SCORE_LIST(options)
          .then((response: ScoreListResponse) => {
            if (populate) {
              commit("populateScores", response);
            }

            commit("loading/SCORE_LIST", false, { root: true });
            resolve(response);
          })
          .catch((error) => {
            commit("clearScores");
            commit("loading/SCORE_LIST", false, { root: true });
            reject(error);
          });
      });
    },
    LIST_SIGNLE_USER(
      { commit }: { commit: Commit },
      options: ListServiceObjectOptions & { populateScore?: boolean }
    ): Promise<ScoreListResponse> {
      // let populate = true;
      // if (options.populateScore == false) populate = false;
      commit("loading/SCORE_LIST_SINGLE_USER", true, { root: true });
      return new Promise((resolve, reject) => {
        SpikeringService.SCORE_LIST(options)
          .then((response: ScoreListResponse) => {
            // if (populate) {
            //   commit("populateScores", response);
            // }

            commit("loading/SCORE_LIST_SINGLE_USER", false, { root: true });
            resolve(response);
          })
          .catch((error) => {
            commit("loading/SCORE_LIST_SINGLE_USER", false, { root: true });
            reject(error);
          });
      });
    },
    GET({ commit }: { commit: Commit }, scoreUniqueId: string) {
      commit("loading/SCORE_GET", true, { root: true });
      return new Promise((resolve, reject) => {
        SpikeringService.SCORE_GET(scoreUniqueId)
          .then((response) => {
            commit("populateScore", response);
            commit("loading/SCORE_GET", false, { root: true });
            resolve(response);
          })
          .catch((error) => {
            commit("clearScore");
            commit("loading/SCORE_GET", false, { root: true });
            reject(error);
          });
      });
    },
    async CALCULATE(
      { commit }: { commit: Commit },
      payload: CalculcateScoreOptions
    ) {
      try {
        commit("loading/SCORE_CALCULATE", true, { root: true });
        const response = await SpikeringService.SCORE_CALCULATE(payload);
        return response;
      } catch (error: any) {
        throw new Error(error);
      } finally {
        commit("loading/SCORE_CALCULATE", false, { root: true });
      }
    },
    async ADD({ commit }: { commit: Commit }, payload: AddScoreOptions) {
      try {
        commit("loading/SCORE_ADD", true, { root: true });
        const response = await SpikeringService.SCORE_ADD(payload);
        return response;
      } catch (error: any) {
        throw new Error(error);
      } finally {
        commit("loading/SCORE_ADD", false, { root: true });
      }
    },
    async UPDATE({ commit }: { commit: Commit }, payload: UpdateScoreOptions) {
      try {
        commit("loading/SCORE_UPDATE", true, { root: true });
        const response = await SpikeringService.SCORE_UPDATE(payload);
        return response;
      } catch (error: any) {
        throw new Error(error);
      } finally {
        commit("loading/SCORE_UPDATE", false, { root: true });
      }
    },
  },
  mutations: {
    clearScores(state: ScoreState) {
      state.scores = {
        page: 1,
        itemsPerPage: 10,
        limit: null,
        total: 0,
        list: [],
      };
    },
    populateScores(
      state: ScoreState,
      scores: {
        page: number;
        itemsPerPage: number;
        limit: number | null;
        total: number;
        list: Score[];
      }
    ) {
      state.scores = scores;
    },
    clearScore(state: ScoreState) {
      state.score = undefined;
    },
    populateScore(state: ScoreState, score: Score) {
      state.score = score;
    },
  },
};

export default scoreModule;
