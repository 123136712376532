import { CaptchaState } from "@/interfaces/module-states";
import { Commit } from "vuex";
import { CaptchaVerifyOptions } from "@/interfaces/service-options";
import SpikeringService from "@/services/spikering.service";
import {CaptchaVerifyResponse} from "@/interfaces/service-response";

const state: CaptchaState = {
}

const captchaModule = {
    namespaced: true,
    state,
    actions: {
        VERIFY({commit}: {commit: Commit}, options: CaptchaVerifyOptions): Promise<CaptchaVerifyResponse> {
            commit('loading/CAPTCHA_VERIFY', true, { root: true });
            return new Promise((resolve, reject) => {
                SpikeringService.CAPTCHA_VERIFY(options)
                    .then((response: CaptchaVerifyResponse) => {
                        commit('loading/CAPTCHA_VERIFY', false, { root: true });
                        resolve(response);
                    })
                    .catch(error => {
                        commit('loading/CAPTCHA_VERIFY', false, { root: true });
                        reject(error);
                    });
            });
        },
    },
    mutations: {

    }
}

export default captchaModule;