import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import setCookie from "@/lib/set-cookie";

const routes: Array<RouteRecordRaw> = [
  // Top-Level Routes
  {
    path: "/",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/view-dashboard.vue"),
    meta: {
      requiresAuth: true,
      title: { key: "routes.home" },
      breadcrumb: [{ name: { key: "home" } }],
    },
  },
  {
    path: "/activate/:token",
    name: "activate",
    component: () =>
      import(/* webpackChunkName: "activate" */ "../views/view-activate.vue"),
    meta: {
      requiresAuth: false,
      title: { key: "routes.activate" },
      breadcrumb: [{ name: { key: "activate" } }],
    },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/view-login.vue"),
    meta: {
      requiresAuth: false,
      title: { key: "routes.login" },
      breadcrumb: [{ name: { key: "login" } }],
    },
    children: [
      {
        path: "/login/:jwt",
        name: "login-via-email",
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/view-login.vue"),
        meta: {
          requiresAuth: false,
          title: { key: "routes.login" },
          breadcrumb: [{ name: { key: "login" } }],
        },
      },
    ],
  },
  {
    path: "/resend-activation",
    name: "resend-activation",
    component: () =>
      import(
        /* webpackChunkName: "resend-activation" */ "../views/resend-activation.vue"
      ),
    meta: {
      requiresAuth: false,
      title: { key: "routes.resend-activation" },
      breadcrumb: [{ name: { key: "resend-activation" } }],
    },
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/view-register.vue"),
    meta: {
      requiresAuth: false,
      title: { key: "routes.register" },
      breadcrumb: [{ name: { key: "register" } }],
    },
  },
  //Normal user
  {
    path: "/post-registration",
    name: "post-registration",
    component: () =>
      import(
        /* webpackChunkName: "post-registration" */ "../views/view-post-registration.vue"
      ),
    meta: {
      requiresAuth: false,
      title: { key: "routes.post-registration" },
      breadcrumb: [{ name: { key: "post-registration" } }],
    },
  },
  //Guest user
  {
    path: "/post-registration-guest",
    name: "post-registration-guest",
    component: () =>
      import(
        /* webpackChunkName: "post-registration" */ "../views/view-post-registration-guest.vue"
      ),
    meta: {
      requiresAuth: false,
      title: { key: "routes.post-registration" },
      breadcrumb: [{ name: { key: "post-registration" } }],
    },
  },
  {
    path: "/post-confirmation",
    name: "post-confirmation",
    component: () =>
      import(
        /* webpackChunkName: "post-confirmation" */ "../views/view-post-confirmation.vue"
      ),
    meta: {
      requiresAuth: false,
      title: { key: "routes.post-confirmation" },
      breadcrumb: [{ name: { key: "post-confirmation" } }],
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/view-profile.vue"),
    meta: {
      requiresAuth: true,
      title: { key: "routes.profile" },
      breadcrumb: [{ name: { key: "profile" } }],
    },
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () =>
      import(
        /* webpackChunkName: "password-reset" */ "../views/view-password-reset.vue"
      ),
    meta: {
      requiresAuth: false,
      title: { key: "routes.password-reset" },
      breadcrumb: [{ name: { key: "password-reset" } }],
    },
  },
  {
    path: "/password-recovery/:token",
    name: "password-recovery",
    component: () =>
      import(
        /* webpackChunkName: "password-recovery" */ "../views/view-password-recovery.vue"
      ),
    meta: {
      requiresAuth: false,
      title: { key: "routes.password-recovery" },
      breadcrumb: [{ name: { key: "password-recovery" } }],
    },
  },
  {
    path: "/scoreboard",
    name: "scoreboard",
    component: () =>
      import(
        /* webpackChunkName: "scoreboard" */ "../views/view-scoreboard.vue"
      ),
    meta: {
      requiresAuth: false,
      title: { key: "routes.scoreboard" },
      breadcrumb: [{ name: { key: "scoreboard", choice: 1 } }],
    },
  },
  {
    path: "/daily-prizes",
    name: "daily-prizes",
    component: () =>
      import(
        /* webpackChunkName: "daily-prizes" */ "../views/view-daily-prizes.vue"
      ),
    meta: {
      requiresAuth: false,
      title: { key: "routes.daily-prizes" },
      breadcrumb: [{ name: { key: "daily-prize", choice: 2 } }],
    },
  },
  {
    path: "/podium",
    name: "podium",
    component: () =>
      import(/* webpackChunkName: "podium" */ "../views/view-podium.vue"),
    meta: {
      requiresAuth: false,
      title: { key: "routes.podium" },
      breadcrumb: [{ name: { key: "podium", choice: 1 } }],
    },
  },
  {
    path: "/leaderboard",
    name: "leaderboard",
    component: () =>
      import(
        /* webpackChunkName: "leaderboard" */ "../views/view-leaderboard.vue"
      ),
    meta: {
      requiresAuth: true,
      title: { key: "routes.leaderboard" },
      breadcrumb: [{ name: { key: "leaderboard" } }],
    },
  },
  {
    path: "/leaderboard/:station",
    name: "leaderboard-station",
    component: () =>
      import(
        /* webpackChunkName: "leaderboard-station" */ "../views/view-leaderboard-station.vue"
      ),
    meta: {
      requiresAuth: false,
      title: { key: "routes.leaderboard-station" },
      breadcrumb: [{ name: { key: "leaderboard-station" } }],
    },
  },
  // Sports
  {
    path: "/athletics",
    name: "athletics",
    component: () =>
      import(
        /* webpackChunkName: "athletics" */ "../views/stations/view-athletics.vue"
      ),
    meta: {
      requiresAuth: true,
      title: { key: "routes.stations.athletics" },
      breadcrumb: [{ name: { key: "stations.athletics" } }],
    },
  },
  {
    path: "/beach-volleyball",
    name: "beach-volleyball",
    component: () =>
      import(
        /* webpackChunkName: "beach-volleyball" */ "../views/stations/view-beach-volleyball.vue"
      ),
    meta: {
      requiresAuth: true,
      title: { key: "routes.stations.beach-volleyball" },
      breadcrumb: [{ name: { key: "stations.beach-volleyball" } }],
    },
  },
  {
    path: "/canoe-sprint",
    name: "canoe-sprint",
    component: () =>
      import(
        /* webpackChunkName: "canoe-sprint" */ "../views/stations/view-canoe-sprint.vue"
      ),
    meta: {
      requiresAuth: true,
      title: { key: "routes.stations.canoe-sprint" },
      breadcrumb: [{ name: { key: "stations.canoe-sprint" } }],
    },
  },
  {
    path: "/cycling",
    name: "cycling",
    component: () =>
      import(
        /* webpackChunkName: "cycling" */ "../views/stations/view-cycling.vue"
      ),
    meta: {
      requiresAuth: true,
      title: { key: "routes.stations.cycling" },
      breadcrumb: [{ name: { key: "stations.cycling" } }],
    },
  },
  {
    path: "/gymnastics",
    name: "gymnastics",
    component: () =>
      import(
        /* webpackChunkName: "gymnastics" */ "../views/stations/view-gymnastics.vue"
      ),
    meta: {
      requiresAuth: true,
      title: { key: "routes.stations.gymnastics" },
      breadcrumb: [{ name: { key: "stations.gymnastics" } }],
    },
  },
  {
    path: "/rowing",
    name: "rowing",
    component: () =>
      import(
        /* webpackChunkName: "rowing" */ "../views/stations/view-rowing.vue"
      ),
    meta: {
      requiresAuth: true,
      title: { key: "routes.stations.rowing" },
      breadcrumb: [{ name: { key: "stations.rowing" } }],
    },
  },
  {
    path: "/sport-climbing",
    name: "sport-climbing",
    component: () =>
      import(
        /* webpackChunkName: "sport-climbing" */ "../views/stations/view-sport-climbing.vue"
      ),
    meta: {
      requiresAuth: true,
      title: { key: "routes.stations.sport-climbing" },
      breadcrumb: [{ name: { key: "stations.sport-climbing" } }],
    },
  },
  {
    path: "/table-tennis",
    name: "table-tennis",
    component: () =>
      import(
        /* webpackChunkName: "table-tennis" */ "../views/stations/view-table-tennis.vue"
      ),
    meta: {
      requiresAuth: true,
      title: { key: "routes.stations.table-tennis" },
      breadcrumb: [{ name: { key: "stations.table-tennis" } }],
    },
  },
  {
    path: "/triathlon",
    name: "triathlon",
    component: () =>
      import(
        /* webpackChunkName: "triathlon" */ "../views/stations/view-triathlon.vue"
      ),
    meta: {
      requiresAuth: true,
      title: { key: "routes.stations.triathlon" },
      breadcrumb: [{ name: { key: "stations.triathlon" } }],
    },
  },
  // Badge route
  {
    path: "/badge/:badgeId",
    name: "badge",
    component: () =>
      import(/* webpackChunkName: "badge" */ "../views/view-badge.vue"),
    meta: {
      requiresAuth: false,
      requiresRole: "WEBAPP",
      title: { key: "routes.badge" },
      breadcrumb: [{ name: { key: "badge", choice: 1 } }],
    },
  },
  {
    path: "/user/:username",
    name: "user",
    component: () =>
      import(/* webpackChunkName: "badge" */ "../views/view-user.vue"),
    meta: {
      requiresAuth: false,
      requiresRole: "WEBAPP",
      title: { key: "routes.username" },
      breadcrumb: [{ name: { key: "username", choice: 1 } }],
    },
  },
  //Webapp routes
  {
    path: "/participants",
    name: "participants",
    component: () =>
      import(
        /* webpackChunkName: "webapp/participants" */ "../views/view-participants.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresRole: "WEBAPP",
      title: { key: "routes.participant" },
      breadcrumb: [{ name: { key: "participant", choice: 2 } }],
    },
  },
  {
    path: "/awards",
    name: "awards",
    component: () =>
      import(
        /* webpackChunkName: "webapp/awards" */ "../views/view-awards.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresRole: "WEBAPP",
      title: { key: "routes.awards" },
      breadcrumb: [{ name: { key: "award", choice: 2 } }],
    },
  },
  // 404
  {
    path: "/:pathMatch(.*)*",
    name: "404-not-found",
    component: () =>
      import(
        /* webpackChunkName: "404-not-found" */ "../views/view-not-found.vue"
      ),
    meta: {
      requiresAuth: false,
      title: { key: "routes.404-not-found" },
      breadcrumb: [{ name: { key: "404-not-found" } }],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
  scrollBehavior: (to) => {
    if (to.hash) {
      return {el: to.hash}
    } else {
      return {left: 0, top: 0}
    }
  },
});

export default router;

router.beforeEach(async (to, from, next) => {
  const isLoggedIn = store.getters["USER/isLoggedIn"];
  const isWebAppUser = store.getters["USER/isWebAppUser"];
  const authenticationMethod = store.getters["USER/authenticationMethod"];
  // Redirect from login and registration to dashboard if already logged in
  if ((to.name === "login" || to.name === "register") && isLoggedIn) {
    next({ path: "/" });
    return;
  }

  // Set the Badge ID.
  if (to.name === "badge" && to.params.badgeId) {
    const badgeId = to.params.badgeId.toString().toUpperCase();
    const badgeRegex = /^([A-Z0-9]){5}$/;

    if (badgeRegex.test(badgeId)) {
      if (!isWebAppUser) {
        if (
          isLoggedIn &&
          authenticationMethod &&
          badgeId !== authenticationMethod.badge
        ) {
          store.dispatch("USER/LOGOUT").then(() => {
            setCookie("badgeId", badgeId, {});
            store.commit("setBadgeId", badgeId);
            return next("/login");
          });
        }
        setCookie("badgeId", badgeId, {});
        store.commit("setBadgeId", badgeId);
        next({ path: "/" });
        return;
      }
    }
  }

  // Redirect to the login page if not logged in and the route requires auth
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isLoggedIn) {
      next({ name: "login" });
      return;
    }
  }

  // Redirect to the dashboard if the user doesn't have the correct role.
  if (to.matched.some((record) => record.meta.requiresRole)) {
    if (to.meta.requiresRole && !isWebAppUser) {
      next({ name: "dashboard" });
      return;
    }
  }

  // Always call next()
  next();
});
