import { createApp } from "vue";
import App from "@/App.vue";
import "@/registerServiceWorker";
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";
import "@/assets/scss/theme.scss";
import "../node_modules/bootstrap/dist/js/bootstrap";
import { VueReCaptcha } from "vue-recaptcha-v3";
import moment from "moment";



import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { faSquare } from "@fortawesome/free-regular-svg-icons";

import {
  faCircleCheck as faCircleCheckSolid,
  faAward as faAwardSolid,
  faLanguage as faLanguageSolid,
  faQuestionCircle as faQuestionCircleSolid,
  faCheckSquare,
  faEllipsisH,
  faSortDown,
  faSortUp,
  faSort,
  faSyncAlt,
  faPlus,
  faChevronUp,
  faChevronDown,
  faExternalLinkAlt,
  faFlagCheckered
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faCircleCheckSolid,
  faAwardSolid,
  faLanguageSolid,
  faQuestionCircleSolid,
  faSquare,
  faCheckSquare,
  faEllipsisH,
  faSortDown,
  faSortUp,
  faSort,
  faSyncAlt,
  faPlus,
  faChevronUp,
  faChevronDown,
  faExternalLinkAlt,
  faFlagCheckered
);

const app = createApp(App);

// Filters
app.config.globalProperties.$filters = {
  timestamp: (date: Date) => {
    return moment(date).format("DD.MM.YYYY, H:i:s");
  },
  kebabize: (str: string) => {
    const whitelist = [".", "-"];

    return str
      .split("")
      .map((letter, idx) => {
        if (whitelist.includes(letter)) {
          return letter;
        }

        return letter.toUpperCase() === letter
          ? `${idx !== 0 ? "-" : ""}${letter.toLowerCase()}`
          : letter;
      })
      .join("");
  },
};

app.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_V3_KEY,
  loaderOptions: {
    useRecaptchaNet: true,
    useEnterprise: false,
    autoHideBadge: true,
  },
});
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(store);
app.use(router);
app.use(i18n);


// Directives

app.directive("format-score", {
  mounted(el, binding) {
    if(!binding.value) return 0
    if (typeof binding.value === "number") {
      el.textContent = binding.value;
      return;
    }
    el.textContent = binding.value.split(".")[0];
  },
  updated(el, binding) {
    if(!binding.value) return 0
    if (typeof binding.value === "number") {
      el.textContent = binding.value;
      return;
    }
    el.textContent = binding.value.split(".")[0];
  },
});

app.mount("#msa-munich2022");
