import { createStore } from "vuex";
import { State } from "@/interfaces/module-states";
import loadingModule from "@/store/modules/app/loading.module";
import toastModule from "@/store/modules/app/toast.module";
import userModule from "@/store/modules/service/user.module";
import scoreModule from "@/store/modules/service/score.module";
import captchaModule from "@/store/modules/service/captcha.module";
import getCookie from "@/lib/get-cookie";
import { AgeGroup } from "@/interfaces/application";
import deleteCookie from "@/lib/delete-cookie";
import  awardModule  from "./modules/service/award.module";

const badgeId = getCookie("badgeId", false);
const state: State = {
  badgeId: badgeId ? badgeId : undefined,
  stations: [
    {
      name: "athletics",
      logo: require("@/assets/img/sports/logo/athletics.svg"),
      cardImg: require("@/assets/img/sports/card-top/ahtletics-thumb_467_default_lg.png"),
      bgPattern: require("@/assets/img/sports/bg-pattern/bg-pattern-athletics.svg"),
      color: "#ff7900",
      pictogram: {
        color: require("@/assets/img/sports/pictogram/color/athletics.svg"),
        white: require("@/assets/img/sports/pictogram/white/athletics.svg"),
      },
      gfreidi: require("@/assets/img/sports/gfreidi/athletics.png"),
    },
    {
      name: "beach-volleyball",
      logo: require("@/assets/img/sports/logo/athletics.svg"),
      cardImg: require("@/assets/img/sports/card-top/beach-volleyball-thumb_468_default_lg.png"),
      bgPattern: require("@/assets/img/sports/bg-pattern/bg-pattern-beach-volleyball.svg"),
      color: "#e57554",
      pictogram: {
        color: require("@/assets/img/sports/pictogram/color/beach-volleyball.svg"),
        white: require("@/assets/img/sports/pictogram/white/beach-volleyball.svg"),
      },
      gfreidi: require("@/assets/img/sports/gfreidi/beach-volleyball.png"),
    },
    {
      name: "canoe-sprint",
      logo: require("@/assets/img/sports/logo/canoe-sprint.svg"),
      cardImg: require("@/assets/img/sports/card-top/canoe-sprint-thumb_469_default_lg.png"),
      bgPattern: require("@/assets/img/sports/bg-pattern/bg-pattern-canoe-sprint.svg"),
      color: "#a8cb3b",
      pictogram: {
        color: require("@/assets/img/sports/pictogram/color/canoe-sprint.svg"),
        white: require("@/assets/img/sports/pictogram/white/canoe-sprint.svg"),
      },
      gfreidi: require("@/assets/img/sports/gfreidi/canoe-sprint.png"),
    },
    {
      name: "cycling",
      logo: require("@/assets/img/sports/logo/cycling.svg"),
      cardImg: require("@/assets/img/sports/card-top/cycling-thumb_470_default_lg.png"),
      bgPattern: require("@/assets/img/sports/bg-pattern/bg-pattern-cycling.svg"),
      color: "#d52b1e",
      pictogram: {
        color: require("@/assets/img/sports/pictogram/color/cycling.svg"),
        white: require("@/assets/img/sports/pictogram/white/cycling.svg"),
      },
      gfreidi: require("@/assets/img/sports/gfreidi/cycling.png"),
    },
    {
      name: "gymnastics",
      logo: require("@/assets/img/sports/logo/gymnastics.svg"),
      cardImg: require("@/assets/img/sports/card-top/gymnastics-thumb_471_default_lg.png"),
      bgPattern: require("@/assets/img/sports/bg-pattern/bg-pattern-gymnastics.svg"),
      color: "#ca005d",
      pictogram: {
        color: require("@/assets/img/sports/pictogram/color/gymnastics.svg"),
        white: require("@/assets/img/sports/pictogram/white/gymnastics.svg"),
      },
      gfreidi: require("@/assets/img/sports/gfreidi/gymnastics.png"),
    },
    {
      name: "rowing",
      logo: require("@/assets/img/sports/logo/rowing.svg"),
      cardImg: require("@/assets/img/sports/card-top/rowing-thumb_472_default_lg.png"),
      bgPattern: require("@/assets/img/sports/bg-pattern/bg-pattern-rowing.svg"),
      color: "#00b2a9",
      pictogram: {
        color: require("@/assets/img/sports/pictogram/color/rowing.svg"),
        white: require("@/assets/img/sports/pictogram/white/rowing.svg"),
      },
      gfreidi: require("@/assets/img/sports/gfreidi/rowing.png"),
    },
    {
      name: "sport-climbing",
      logo: require("@/assets/img/sports/logo/sport-climbing.svg"),
      cardImg: require("@/assets/img/sports/card-top/sport-climbing-thumb_473_default_lg.png"),
      bgPattern: require("@/assets/img/sports/bg-pattern/bg-pattern-sport-climbing.svg"),
      color: "#db2b6f",
      pictogram: {
        color: require("@/assets/img/sports/pictogram/color/sport-climbing.svg"),
        white: require("@/assets/img/sports/pictogram/white/sport-climbing.svg"),
      },
      gfreidi: require("@/assets/img/sports/gfreidi/sport-climbing.png"),
    },
    {
      name: "table-tennis",
      logo: require("@/assets/img/sports/logo/table-tennis.svg"),
      cardImg: require("@/assets/img/sports/card-top/table-tennis-thumb_475_default_lg.png"),
      bgPattern: require("@/assets/img/sports/bg-pattern/bg-pattern-table-tennis.svg"),
      color: "#1d71bb",
      pictogram: {
        color: require("@/assets/img/sports/pictogram/color/table-tennis.svg"),
        white: require("@/assets/img/sports/pictogram/white/table-tennis.svg"),
      },
      gfreidi: require("@/assets/img/sports/gfreidi/table-tennis.png"),
    },
    {
      name: "triathlon",
      logo: require("@/assets/img/sports/logo/triathlon.svg"),
      cardImg: require("@/assets/img/sports/card-top/triathlon-thumb_474_default_lg.png"),
      bgPattern: require("@/assets/img/sports/bg-pattern/bg-pattern-triathlon.svg"),
      color: "#fecb00",
      pictogram: {
        color: require("@/assets/img/sports/pictogram/color/triathlon.svg"),
        white: require("@/assets/img/sports/pictogram/white/triathlon.svg"),
      },
      gfreidi: require("@/assets/img/sports/gfreidi/triathlon.png"),
    },
  ],
  ageGroup: {
    label: "18+",
    value: "AGE18",
  },
  ageGroups: [
    {
      label: "6-9",
      value: "AGE6TO9",
    },
    {
      label: "10-13",
      value: "AGE10TO13",
    },
    {
      label: "14-17",
      value: "AGE14TO17",
    },
    {
      label: "18+",
      value: "AGE18",
    },
  ],
  avatars: [
    "/img/avatars/EC22_MASCOT_COMIC_300px_300px_Allgemein.png",
    "/img/avatars/EC22_MASCOT_COMIC_300px_300px_ATH.png",
    "/img/avatars/EC22_MASCOT_COMIC_300px_300px_BVB.png",
    "/img/avatars/EC22_MASCOT_COMIC_300px_300px_CLB.png",
    "/img/avatars/EC22_MASCOT_COMIC_300px_300px_CSP.png",
    "/img/avatars/EC22_MASCOT_COMIC_300px_300px_CYC.png",
    "/img/avatars/EC22_MASCOT_COMIC_300px_300px_GAV.png",
    "/img/avatars/EC22_MASCOT_COMIC_300px_300px_ROW.png",
    "/img/avatars/EC22_MASCOT_COMIC_300px_300px_TRI.png",
    "/img/avatars/EC22_MASCOT_COMIC_300px_300pxTTE.png",
    "/img/avatars/EC22_RO22_ATHLETES_COMIC_AlmaBestvater.png",
    "/img/avatars/EC22_RO22_ATHLETES_COMIC_BoBaehre.png",
    "/img/avatars/EC22_RO22_ATHLETES_COMIC_ChristinaHering.png",
    "/img/avatars/EC22_RO22_ATHLETES_COMIC_ClemensWickler.png",
    "/img/avatars/EC22_RO22_ATHLETES_COMIC_FeliciaLaberer.png",
    "/img/avatars/EC22_RO22_ATHLETES_COMIC_FelixRemuta.png",
    "/img/avatars/EC22_RO22_ATHLETES_COMIC_FrancaHenseleit.png",
    "/img/avatars/EC22_RO22_ATHLETES_COMIC_GudrunStock.png",
    "/img/avatars/EC22_RO22_ATHLETES_COMIC_LaraLessmann.png",
    "/img/avatars/EC22_RO22_ATHLETES_COMIC_MaxLemke.png",
    "/img/avatars/EC22_RO22_ATHLETES_COMIC_OliverZeidler.png",
    "/img/avatars/EC22_RO22_ATHLETES_COMIC_SabineWinter.png",
    "/img/avatars/EC22_RO22_ATHLETES_COMIC_SimonHenseleit.png",
  ],
  languages: [
    {
      locale: "en-US",
      label: "English",
    },
    {
      locale: "de-DE",
      label: "Deutsch",
    },
  ],
  errorCodes: [
    { code: "EUSDP-p00019-USER-ERROR-21" },
    { code: "EUSDP-p00019-USER-ERROR-01" },
    { code: "EUSDP-p00001-EUSDP-ERROR-04" },
  ],
};

export default createStore({
  state,
  getters: {
    athletics(state: State) {
      return state.stations.filter(
        (station) => station.name === "athletics"
      )[0];
    },
    "beach-volleyball"(state: State) {
      return state.stations.filter(
        (station) => station.name === "beach-volleyball"
      )[0];
    },
    "canoe-sprint"(state: State) {
      return state.stations.filter(
        (station) => station.name === "canoe-sprint"
      )[0];
    },
    cycling(state: State) {
      return state.stations.filter((station) => station.name === "cycling")[0];
    },
    gymnastics(state: State) {
      return state.stations.filter(
        (station) => station.name === "gymnastics"
      )[0];
    },
    rowing(state: State) {
      return state.stations.filter((station) => station.name === "rowing")[0];
    },
    "sport-climbing"(state: State) {
      return state.stations.filter(
        (station) => station.name === "sport-climbing"
      )[0];
    },
    "table-tennis"(state: State) {
      return state.stations.filter(
        (station) => station.name === "table-tennis"
      )[0];
    },
    triathlon(state: State) {
      return state.stations.filter(
        (station) => station.name === "triathlon"
      )[0];
    },
    ageGroup(state: State): AgeGroup {
      return state.ageGroup;
    },
    ageGroups(state: State): AgeGroup[] {
      return state.ageGroups;
    },
    avatars(state: State) {
      return state.avatars;
    },
    errorCodes(state: State) {
      return state.errorCodes;
    },
  },
  mutations: {
    setBadgeId(state: State, badgeId: string) {
      state.badgeId = badgeId;
    },
  },
  actions: {},
  modules: {
    // APP
    loading: loadingModule,
    toast: toastModule,
    // SERVICE
    USER: userModule,
    SCORE: scoreModule,
    CAPTCHA: captchaModule,
    AWARD:awardModule
  },
});
