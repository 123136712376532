import { v4 } from 'uuid';
import {Commit} from "vuex";
import {ToastMessage, ToastState} from "@/interfaces/module-states";

const toastModule = {
    namespaced: true,
    state: {
        list: []
    },
    actions: {
        success({commit}: {commit: Commit}, toastMessage: ToastMessage) {
            commit('add', {
                type: 'success',
                title: toastMessage.title,
                message: toastMessage.message,
                duration: toastMessage.duration,
                id: v4()
            });
        },
        danger({commit}: {commit: Commit}, toastMessage: ToastMessage) {
            commit('add', {
                type: 'danger',
                title: toastMessage.title,
                message: toastMessage.message,
                duration: toastMessage.duration,
                id: v4()
            });
        },
        warning({commit}: {commit: Commit}, toastMessage: ToastMessage) {
            commit('add', {
                type: 'warning',
                title: toastMessage.title,
                message: toastMessage.message,
                duration: toastMessage.duration,
                id: v4()
            });
        },
        info({commit}: {commit: Commit}, toastMessage: ToastMessage) {
            commit('add', {
                type: 'info',
                title: toastMessage.title,
                message: toastMessage.message,
                duration: toastMessage.duration,
                id: v4()
            });
        }
    },
    mutations: {
        add(state: ToastState, toastMessage: ToastMessage) {
            state.list.push(toastMessage);

            if (!toastMessage.duration) {
                toastMessage.duration = 6000;
            }

            if (toastMessage.duration !== -1) {
                setTimeout(() => {
                    const index = state.list.findIndex(x => x.id === toastMessage.id);
                    state.list.splice(index, 1);
                }, toastMessage.duration);
            }
        },
        remove(state: ToastState, id: string) {
            const index = state.list.findIndex(toastMessage => toastMessage.id === id);
            state.list.splice(index, 1);
        }
    }
}

export default toastModule;
