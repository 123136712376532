import {v4} from 'uuid';
import moment from 'moment';

export default function createInfoObject(type: string, data?: object, context?: string, mode = '') {
    return {
        header: {
            version: '1.0.2',
            id: v4(),
            parentId: '',
            type: 'INFO_OBJECT_DEFAULT',
            status: 'NEW',
            history: [
                {
                    step: '1',
                    timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
                    unit: v4(),
                    type: 'SERVICE_REQUEST',
                    status: 'NEW'
                }
            ]
        },
        payload: {
            type: type,
            context: context,
            mode: mode,
            data: data
        }
    };
}
